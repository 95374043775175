
#sideBarColumn {
  padding-left:0;
  padding-right:0;
  background-color: #F8F9FA;
}

main {
  /* width:95% ;  */
  /* padding-left: 3rem; */
  min-height: calc(100vh - 232px - 56px);
  margin-right:0px !important;
}

a {
  text-decoration: none !important;
}

.pointer {
  cursor: pointer !important;
}

.checkbox-md {
  width:24px;
  height:24px;
  margin-right:25px !important;
}

.radio-md {
  width:24px;
  height:24px;
  margin-right:8px !important;
}



.greyCard:hover {
  background-color: #F8F9FA !important;
}

.greyCard:active {
  background-color: #F8F9FA !important;
}

.greyBackground {
  background-color: #E9ECEF !important;
}

.halfOpaque {
  opacity: 0.6;
}

.halfWidth {
  width:50%
}

.fitContent {
  width: fit-content;
}


.greenBadge {
  background-color: rgba(0,201,167,.1);
  color: #00c9a7 !important;
}

.yellowBadge {
  background-color:#FEF0E8;
  color: #F79B67 !important;
}

.redBadge {
  background-color: #F8E1E0;
  color: #d9534f !important;
}

.blueBadge {
  background-color: rgba(55,125,255,.1);
  color: #377dff !important
}

.lightGreyBadge {
  background-color: rgb(247,247,247) !important;
  color:rgb(143,143,143) !important;
}


.otherLightGreyBadge {
  color: #71869d !important;
  background-color: rgba(113,134, 157, .1) !important;
}


.lightBlueBadge {
  /* background-color: rgb(8, 45, 66); */
  background-color: rgb(60,184,255);
  color: #fff !important;
}


.veryLightGrey {
  background-color: #F9FAFC;
  color:#8c98a4
}


.lightGreyText {
  color: #8e9498;
}

.veryLightGreyText {
  color: #cacfd4;
}

.lightGreyButton:hover {
  background-color: #ececec !important;
}


.bg-error {
  background-color: rgb(180, 137, 143);
}

.lightCard {
  border: 1px solid rgba(231,234,243,.7) !important;
}


.lightCardNoHover {
  border: 1px solid rgba(231,234,243,.7) !important;
}

.lightCard:hover {
  box-shadow: 0 6px 12px rgba(140,152,164,.25)!important;
  color:#0052ea !important;
}

.lightCardNoShadow {
  border: 1px solid rgba(231,234,243,.7) !important;
}

.lightCardNoShadow:hover {
  border: 1px solid rgba(231,234,243,.7) !important;
  color:#0052ea !important;
}

.lightCardPopout {
  border: 1px solid rgba(231,234,243,.7) !important;
  box-shadow: 0 6px 12px rgba(140,152,164,.25)!important;
}

.selectedSearchResultCard {
  background-color: #e6e6e6 !important;
}


.step {
  background: #cccccc;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; 
}
.stepComplete {
  background: #018937;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; 
}



.greenBtn {
  background-color: rgba(0,201,167,.1);
  color: #00c9a7 !important;
  border-radius: 4px;
  border: 2px solid green;
}
.greenBtn:hover {
  background-color: rgba(0,201,167,.1);
  color: #00c9a7 !important;
}



.copyIcon:hover {
  color:#bec6d6;
}



#tooltip-disabled > .tooltip-inner {
  background-color: #fff;
  opacity:1;
  color: #000;
  border: 2px solid #000000;
}



.selectionDiv:hover {
  background-color: rgb(230, 230, 230) !important;
  border-radius: 5px;
}


.font-small {
  font-size: 80%;
  font-weight: 400;
}

.font-large {
  font-size: 120%;
}


/* dark lighter navbar */
/* 343A40 */


/* input.checkbox-light[type=checkbox] {
  border:1px solid #D6DBEB !important;
} */


/* .custom-checkbox .checkbox-light:checked ~ .custom-control-label::before {
  background-color: green!important;
}

.custom-checkbox .checkbox-light:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}

.custom-checkbox .checkbox-light:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}

.custom-checkbox .checkbox-light:active ~ .custom-control-label::before {
  background-color: #C8FFC8; 
} */


.timeFormSelect {
  border-color: #CED4DA;
  border-radius: .25rem;
}


.rotate {
  transform: rotate(360deg) !important;
  transition: 3s !important;
}




.bg-light-blue {
  background-color: #AEE6FF !important;
}



.link-underline:hover {
  text-decoration: blue !important;
}




.text-hover-blue:hover {
  color: #007BFF;
}





.MuiTableRow-root {
  font-size:small;
}








ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #d4d9df;
  left: 22px;
  width: 16px;
  height: 16px;
  z-index: 400;
}

/* Timeline on report exam schedule is slightly less left */

ul.timeline.reportExamScheduleTimeline:before {
  left: 8px;
}

ul.timeline.reportExamScheduleTimeline > li:before {
  left: 1px;
}


.cardTopProgressBar {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; 
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height:4px !important;
}


.print-only, .print-only-footer {
  display: none;
}

.print-black-bg {
  background-color: black;
}



.messagesList {
  max-height: 400px;
  overflow-y: scroll;
}





@media print {
  body {
      zoom: 80% !important;
      /* -moz-transform: scale(.9); Firefox */
      /* -moz-transform-origin: 0 0; */
      /* For Chrome */
      -webkit-print-color-adjust: exact !important;
      /* For Firefox */
      color-adjust: exact; 
  }

  .printSmall {
      font-size: "0.8rem" !important;
  }

  .noprint {
      display: none;
  }

  .print-black-bg {
      background-color: black !important;   
  }

  .print-only {
      display: block;
  }

  .print-only-footer{
      display: block;
      position: fixed;
      bottom: 15px;
  }

  #printPage {
      margin: 0px !important;
      margin-top: 20px !important;
  }

  .bg-dark {
      color: white !important;
      background-color: rgb(0, 0, 0) !important;
  }

}



@supports (-moz-appearance:none) {
  .no-print-firefox {
      display: none !important;
    }

  .print-firefox {
      display: block !important;
      font-size: 0.9rem;
  }

  @media print {
      .firefox-print-small {
          /* font-size:0.9rem; */
          font-size:0.8rem !important;
      }
      .firefox-print-small h6{
          /* font-size:0.9rem; */
          font-size:0.8rem;
      }
  }
 
}

@-moz-document url-prefix() { 
  @media print {
      .selector {
          font-size: 0.6rem !important;
      }
  }
  
}

/* @media all and (max-width: 2000px) {
  .messagesList {
    max-height: 800px;
  }
} */


@media all and (max-width: 1600px) {
 
  /* main {
      width:96% !important;
  } */
}


@media all and (max-width: 768px) {
  main {
      padding-left: 1rem;
      padding-right:1rem;
      
  }
  main {
      width:100% ;
  }
}


@media all and (max-width:576px) {
  main {
      padding-left: 1rem !important;
      width:100%;
      padding-right: 1rem;
  }
  .border-end {
      border:none !important;
  } 
}


